export default {
    title: 'Deeper Concepts',
    desc: 'Dive into deeper topics realted to building products like UX/UI Design, Product Discovery, creating Product Roadmaps and validating products with A/B testing',
    1: {
        title: 'User Experience',
        desc: `By now, you've gained a high-level understanding of product management, the business concepts product managers must understand, and the PM's various roles, tools, and responsibilities. Over the next few modules, you will keep building the skills that will get you hired, with a focus on some of the key activities that make up what is known as product discovery. 
        \n
        Some of the skills you will build in this phase of the program are related to user experience (UX) design and research. Other skills you will build will prepare you to do core product work.`,
        links: [
            {
                title: 'What Is UX Design? - A Full Overview',
                url: 'https://youtu.be/SRec90j6lTY?si=gvJwSfKq7dW7oJxV',
            },
            {
                title: 'Complete UX & UI Design Bootcamp',
                url: 'https://www.uxuiopen.com'
            }
        ]
    },
    2: {
        title: 'Design Thinking',
        desc: `Now that you know a bit about design, it's time to get familiar with design thinking. This term means something a bit more specific than thinking about design. Developed at the Stanford design school (or as it is known, the D School), design thinking is an approach (and iterative process) to identifying and solving the problems that people experience.
        \n
        Being able to discuss design thinking and use its concepts is very useful for aspiring PMs. Not only can it help you do your job, but it is also an incredibly popular concept among tech companies and other organizations.`,
        links: [
            {
                title: 'What Is Design Thinking?',
                url: 'https://youtu.be/gnWj97CEjeo?si=64WYBnOb_PaBaCiu',
            },
            {
                title: 'Complete UX & UI Design Bootcamp',
                url: 'https://www.uxuiopen.com'
            }
        ]
    },
    3: {
        title: 'Storyboarding',
        desc: `A user journey is essentially what it sounds like. It is the journey that your user is on when they engage your product and use it to reach a goal. In the discovery phase of a product, you will seek to understand the paths a user might take through your product to accomplish their goals. This understanding is foundational to your success as a PM.`,
        links: [
            {
                title: 'Rose, Thorn, Bud – Problem Solving Method',
                url: 'https://youtu.be/LKgXTGHzH50?si=ypsDvVRJ4k1DhiUQ',
            },
            {
                title: 'Complete UX & UI Design Bootcamp',
                url: 'https://www.uxuiopen.com'
            }
        ]
    },
    4: {
        title: 'Information Architecture',
        desc: `In your word processor, where would you find the copy function in the menu? Where would you go to create a new table or to import an image into a document?
        \n
        Many tech products have a shared problem—how do you organize all the features in a way that makes sense? E-commerce sites have a specific version of this problem—how do you organize all the stuff that you're selling so that it's easy to browse and find a product? And how should you name these features so that users will quickly recognize their function?
        \n
        Information architecture is the umbrella term for the method used to address these problems. In this checkpoint, you will learn how to organize content to improve your product's user experience.`,
        links: [
            {
                title: 'Card Sorting Technique - Planning a website',
                url: 'https://youtu.be/FTzHeYPB9c8?si=wsLAmYL0L3P9Oh2C',
            }
        ]
    },
    5: {
        title: 'Survey & NPS',
        desc: `For product managers, surveys are an essential tool for digging deeper into what's really happening with your users and your product. In particular, PMs use a certain kind of survey question that later becomes the net promoter score (NPS). 
        \n
        You may recall from the business metrics checkpoint you completed earlier in this program that NPS is often one of your key metrics. In this checkpoint, you'll learn more about how to craft surveys, how to get people to respond to them, and how to use NPS to diagnose your product and understand whether or not you're on the right track.`
    },
    6: {
        title: 'Presenting Data',
        desc: `Once you collect data from surveys and other research activities, you'll need to communicate it to others so they can understand the results and insights you've gathered. 
        \n
        But creating great data visualizations is no trivial matter. While many software tools can help you create charts and graphs, it takes considerable skill to use these tools effectively.`,
        links: [
            {
                title: `The best stats you've ever seen | Hans Rosling`,
                url: 'https://youtu.be/hVimVzgtD6w?si=W150wotc1zbt22jt',
            },
            {
                title: 'Google Sheets Tutorial for Beginners',
                url: 'https://youtu.be/FIkZ1sPmKNw?si=w9zlOH8sRHCByMhs',
            }
        ]
    },
    7: {
        title: 'Interaction Design',
        desc: `Whether you have a UX team or you're designing features yourself, you need to understand the basics of good interaction design. You might think it's easy to throw some buttons and links on a page to make an app, but it's actually very complex and difficult to create an experience that's intuitive and easy to use.
        \n
        Thankfully, there are a few basic principles that can quickly improve your design skills. This module will focus on developing these skills, including implementing design psychology, creating forms, and crafting interactive prototypes. By the end of this module, you will be creating and submitting a fully functional interactive prototype of a product.`,
        links: [
            {
                title: 'Complete UX & UI Design Bootcamp',
                url: 'https://www.uxuiopen.com'
            }
        ]
    },
    8: {
        title: 'Interaction Flows',
        desc: `Now that you are able to identify the individual page elements that make up interaction design, you can start paying attention to the way they come together into more complex flows. Certain user flows have common conventions. You should incorporate these conventions if you can; the familiarity of them will make it easy for users to understand and follow the flow.
        \n
        In this checkpoint, you will dive into some specific commonly used flows, like registration forms and search functions. You will also learn about flow best practices, the use of user interface (UI) libraries, and how to make products accessible to users of all abilities.`
    },
    9: {
        title: 'Design Psychology',
        desc: `Modern product design is about more than creating buttons and form fields. It's also about the psychological and emotional aspects of humans interacting with technology. In the same way that modern marketing uses messaging and imagery to make products appeal to potential customers, designers can apply their own practices to make their products more engaging and desirable.`,
        links: [
            {
                title: 'The Laws of UX ',
                url: 'https://youtu.be/fYs2Mdyasuc?si=EGWbblwydS2t_FKn',
            },
            {
                title: 'How Apple and Nike have branded your brain',
                url: 'https://youtu.be/4eIDBV4Mpek?si=3i8jYh2OGv4IAjxI',
            },
            {
                title: 'Complete UX & UI Design Bootcamp',
                url: 'https://www.uxuiopen.com'
            }
        ]
    },
    10: {
        title: 'Sketching & Wireframing',
        desc: `In order to create engaging products, you will need to lead your team in the planning of all the detailed interactions that your users will experience. But great interaction designs don't come on the first try. You might go through a dozen iterations before settling on the details of the designs that you will eventually build.
        \n
        In much the same way that MVPs are used to test out product hypotheses, designers use sketches and wireframes to help test design concepts. In this checkpoint, you will learn how to use these techniques to quickly express and test design concepts.`,
        links: [
            {
                title: 'Figma UX tutorial for beginners - Wireframe',
                url: 'https://youtu.be/D4NyQ5iOMF0?si=UrkJnUdDaWGKucTk',
            },
            {
                title: 'Complete UX & UI Design Bootcamp',
                url: 'https://www.uxuiopen.com'
            }
        ]
    },
    11: {
        title: 'Presenting Designs',
        desc: `As you already know, a big part of the PM role is communicating the decisions you've made about the product and the designs. You'll communicate this to various audiences and for various purposes. Your developers need to understand these designs on a different level than your boss or executive team will.`
    },
    12: {
        title: 'Prioritisation Features',
        desc: `Deciding what to work on next is one of the most important tasks you'll have as a product manager. Whether you're building a roadmap or deciding on the scope of a feature that is going to be part of the roadmap, you'll constantly have to make decisions about what will make the cut and what won't. 
        \n
        You will need to consider many factors, from how much value a certain feature will add for your users to how complex it is to build. Often, you will have limited resources (like time, budget, or team members) and will need to make tough choices.`,
        links: [
            {
                title: 'How To Prioritise Features',
                url: 'https://youtu.be/9iq2Fq-XrRc?si=kCOIwZpC9F76FVYQ',
            },
            {
                title: 'The MoSCoW Prioritization Method',
                url: 'https://youtu.be/NJ2JM_PKXjk?si=nJLx69QYf2-Tx6aq',
            }
        ]
    },
    13: {
        title: 'Running Meetings',
        desc: `The work you do as a product manager is all about communication, and your work with roadmaps is no exception to this. You will have many conversations with stakeholders as you build your roadmap and will need to artfully manage those relationships, run effective meetings, and handle the feedback you receive. In this checkpoint, you will spend some time developing the communication and collaboration skills essential to your success as a PM.`,
        links: [
            {
                title: 'Stakeholder Engagement Tips: 5 Tips For Project Managers',
                url: 'https://youtu.be/APc9S_8v7YY?si=SCJSNNrsH-Cec67j',
            },
            {
                title: '10 Tips for Conducting Effective User Interviews',
                url: 'https://www.forbes.com/sites/forbestechcouncil/2022/05/06/10-pro-tips-to-master-user-interviews/',
            },
        ]
    },
    14: {
        title: 'Timelines & Tools',
        desc: 'Product managers use a variety of tools to help manage the tasks that their teams need to complete. Roadmapping tools are software tools that let you easily make changes to a roadmap and see how a change in one task will affect your timeline further down. '
    },
    15: {
        title: 'Usability Tests',
        desc: `Developing products is all about making decisions. And as a product manager, you'll help your team make many decisions about how your software or application should be used. But how will you know if your product works the way it's supposed to? You and your team know the product well, and the interactions seem obvious to you, but will end users actually know how to use it?
        \n
        To increase your confidence in your designs—and to find and fix flaws in what you've built—you can test how people engage with your products. That's where usability tests come in. Usability tests can show you where users succeed, stumble, or get stuck as they try to accomplish tasks within your products.`,
        links: [
            {
                title: 'Introducing a Participant to a Usability Test: A Demonstration',
                url: 'https://youtu.be/bcfqmx2hnUQ?si=blTUrrycMOQi90ZO',
            },
            {
                title: 'Usability testing 101: Types of usability testing & the benefits | Maze',
                url: 'https://youtu.be/7pAOkWLW1Oo?si=ZdEp4M67J_ajle8t',
            }
        ]
    },
    16: {
        title: 'A/B Testing',
        desc: `Most organizations today value data-driven decision-making. In fact, data is often more important than opinions or other factors that might influence a team's or company's priorities. And typically, the best way to generate reliable data about a product is to enable real people to make real choices.
        \n
        So, one solution to choosing between different options for your product is to test them out with your real users. You do this by running different versions of your product simultaneously to see which one performs best. This is called A/B testing and is sometimes also known as split testing.
        \n
        It is a common method used by many companies to try out new designs or features in a product. In this checkpoint, you'll learn more about A/B testing and how it is used to make product decisions.`,
        links: [
            {
                title: 'How To A/B Test a Product',
                url: 'https://youtu.be/jEpwNaHjD68?si=Zix3BtjH29xCX-k7',
            },
            {
                title: 'Complete UX & UI Design Bootcamp',
                url: 'https://www.uxuiopen.com'
            }
        ]
    },
    17: {
        title: 'A/B Test Results',
        desc: `You came up with a hypothesis, created design variations, put together a test plan, and ran your A/B test. Now what? How will you understand those test results and—more importantly—turn them into actionable steps you and your team should take next?
        \n
        In this checkpoint, you will learn a bit about the statistical logic behind your test results. This will allow you to better understand the outcomes of your A/B tests, validate their accuracy, and make decisions based on them. You also need to learn how to communicate these concepts and results to others and discuss the validity of your tests with different stakeholders.`,
        links: [
            {
                title: 'Complete UX & UI Design Bootcamp',
                url: 'https://www.uxuiopen.com'
            }
        ]
    }
}