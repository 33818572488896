export default {
    title: 'Core Topics',
    desc: `Explore foundations of Product Management.
    \n
    Product management is an exciting—and quickly growing—career field. If you find it a bit confusing, you are not alone. Product manager (PM, for short) can mean different things in different companies. 
    \n
    Some PMs are technical. Some are more oriented toward sales or product development. And there are varieties, like product owners or product marketing managers. The good news? All of this means there are a lot of opportunities for people like you to find their first foothold.
    `,
    1: {
        title: 'Product Management 101',
        desc: `What is product management all about? While you no doubt know more than the average person, you might not be able to explain exactly what product managers do or how their work contributes to the business objectives of modern companies.
        \n
        The goal of this checkpoint, and ultimately this entire module, is to give you a high-level overview of the product management field. You'll learn about a product manager's skills, responsibilities, and collaborators, and you'll start thinking about how to discuss these fundamental concepts. Gradually, you'll become familiar with the professional language that would show employers that you're ready for a career in product. In other words, you will start thinking and talking like a product manager.`,
        links: [
            {
                title: 'The 5 qualities of great products',
                url: 'https://www.youtube.com/watch?v=K6a_u62eXso',
            },
            {
                title: 'What do Product Managers actually do?',
                url: 'https://youtu.be/FqBvTFhS1ig'
            }
        ]
    },
    2: {
        title: 'Role of Product',
        desc: `Product management is inherently collaborative, and product managers must understand the overall ecosystem of a modern business organization in order to effectively bring products to market.
        \n
        In this checkpoint, you'll learn how companies are organized, how product fits in, and how those things affect product management work. Product managers are most likely to work at a startup or publicly traded company, so those types of companies will be our focus.`,
        links: [
            {
                title: 'Organizational Structure',
                url: 'https://youtu.be/zUd0UNHyy60?si=vJgOU3HUYtcPCRhn',
            },
            {
                title: 'The difference between Product, Program and Project Management',
                url: 'https://youtu.be/iuxdmHp8NN4',
            }
        ]
    },
    3: {
        title: 'Daily routine as a PM',
        desc: `When preparing for your new career, you are probably wondering: what does the day-to-day of a product manager look like? \
        \n
        This checkpoint will also attempt to describe these tasks and give you a general sense of what you can expect, including the main responsibilities of product managers, what other teams will be doing around you, and how those teams intersect with each other.`,
        links: [
            {
                title: 'Day in the Life of a Product Manager | Confluence Your Way',
                url: 'https://youtu.be/j3vIhObhxWQ?si=FpvJFYHu3oXgthKN'
            },
            {
                title: 'Day in the Life of a Product Manager | Yelp',
                url: 'https://youtu.be/R0ffUjavxxs?si=VdqKOavFIoXWOCCj'
            }
        ]
    },
    4: {
        title: 'Presentation 101',
        desc: `As a product manager, you'll spend a lot of time communicating to a variety of audiences. Often, that means giving presentations. These may take various forms, from recapping interviews with users to reviewing your upcoming priorities. You will give these presentations, but often they will also be shared as a reference for those that may or may not have attended your presentation.`,
        links: [
            {
                title: 'Steve Jobs announcing the first iPhone in 2007',
                url: 'https://youtu.be/wGoM_wVrwng?si=jr71xw8Bh7oQQVJn'
            },
            {
                title: 'PowerPoint Storytelling: How McKinsey, Bain and BCG create compelling presentations',
                url: 'https://youtu.be/HnlWiVs9P5o?si=ARFsBbDhGg-LaoVv'
            }
        ]
    },
    5: {
        title: 'Products and Companies',
        desc: `In this checkpoint, you'll start to explore how product strategy varies based on the type of company, industry, and audience. As you read, ask yourself: what sort of company is the best fit for my personality or past experiences? Am I particularly excited about a certain industry? Use the information in this checkpoint to think about the type of companies you'd like to work for when you graduate from this program`,
        links: [
            {
                title: 'B2B and B2C Product Management - How are they different? Which one is better?',
                url: 'https://youtu.be/mmSZ_Xk79mc?si=loIhcfSXXsai-IOq'
            }
        ]
    },
    6: {
        title: 'Business Models',
        desc: `In most product management roles, you'll be responsible in part or whole for the revenue and profits of the product you'll oversee. To create successful products, you will need a basic understanding of how businesses make money and how they grow. In this checkpoint, you will dive deeper into these topics. This will help you think strategically when making decisions about your product.`,
        links: [
            {
                title: 'Freemium: The First Business Model of the 21st Century',
                url: 'https://youtu.be/ZOKcedfE_nM?si=sN9Cy9OWcMkaZIyJ'
            },
            {
                title: 'Anu Hariharan - Nine Business Models and the Metrics Investors Want',
                url: 'https://youtu.be/PTg3RZPXgLg?si=1jQjTorYSh8okp0t'
            }
        ]
    },
    7: {
        title: 'Metrics',
        desc: `As a product manager, you will be responsible for some or all of the metrics used to measure your product's performance. These metrics are usually tied to your company's business model and industry, which is why you're learning about it now.
        \n
        Many products share the same metrics, but a few are different in special ways that you'll need to be aware of. You'll be expected to be fluent in these terms, so the sooner you master them, the sooner you can spend your time focused on the rest of your product work.`,
        links: [
            {
                title: 'What is a KPI? What are KPIs? Key Performance Indicators',
                url: 'https://youtu.be/soiChkomKmo?si=40iWAcQcd8vd6aMG'
            },
            {
                title: 'Product Metrics: How to measure product success',
                url: 'https://youtu.be/wtDFgx41Rho?si=VzR4kohTSFps_LEU'
            }
        ]
    },
    8: {
        title: 'OKRs',
        desc: `Objectives and key results, better known as OKRs, is a framework used by many tech companies for setting and tracking goals on a company, team, and even individual level.
        \n
        First introduced by Andy Grove at Intel in the 1970s, OKRs made their way in 1999 to a startup named Google. They became central to Google's culture, and cofounder Larry Page credits much of Google's amazing success with their use. Today, the adoption of OKRs is so widespread among tech companies that you're going to spend this entire checkpoint learning about them.`,
        links: [
            {
                title: 'Objectives and Key Results explained ',
                url: 'https://youtu.be/1nEyzZnSsTg?si=u0wuJJMuo1xitYDJ',
            }
        ]
    },
    9: {
        title: 'Finding Users',
        desc: `In this checkpoint, you'll begin to explore how to get in touch with your users and what you need to consider to do so effectively. Understanding who your users are, what they care about, and how to get them to share their perspective with you is going to be a huge part of your success as a PM.`,
        links: [
            {
                title: 'Eric Migicovsky - How to Talk to Users',
                url: 'https://youtu.be/MT4Ig2uqjTc?si=CL0w3OhkVZVrJhsG',
            },
            {
                title: 'User Personas Explained',
                url: 'https://youtu.be/Ehw8lAZMJMM?si=T-RgzeVZDSi_KFHP',
            }
        ]
    },
    10: {
        title: 'Lean Customer Development',
        desc: `If product-market fit is your goal, then you need a few tools to help you get there. Many companies use techniques like the ones you'll learn about here to help them get to product-market fit faster. Many of these methods have been popularized by startups, but now even larger companies are seeing the value of the startup mentality, or lean product development.`,
        links: [
            {
                title: 'Problem-statement Focused Hypotheses',
                url: 'https://youtu.be/z_EFbvxg6WQ?si=WX3q6BUqofiDN9mC',
            }
        ]
    },
    11: {
        title: 'Minimum Viable Product',
        desc: `MVPs are all the rage in product management. You'll hear the question "What's the MVP?" over and over again at work and in your job interviews. This checkpoint will focus on this essential tool in a product manager's toolkit. You will learn what MVPs are, how to ship them, and how to answer those interview questions about them`,
        links: [
            {
                title: 'How to Build An MVP | Startup School',
                url: 'https://youtu.be/QRZ_l7cVzzU?si=Hl92o7wul3K39cM_',
            },
            {
                title: 'Dropbox Original MVP Explainer Video',
                url: 'https://youtu.be/iAnJjXriIcw?si=W794ZEEhllOPuI-m',
            }
        ]
    },
    12: {
        title: 'Interviewing Users',
        desc: `One of the most important skills you'll develop as a product manager is learning to communicate effectively with a variety of audiences. One major form of communication you'll use is interviews with users and stakeholders. Conducting effective interviews will be crucial to your success, as it will allow you to obtain the information you need to make good decisions about your product and priorities.`,
        links: [
            {
                title: 'Lecture 16 - How to Run a User Interview (Emmett Shear)',
                url: 'https://youtu.be/qAws7eXItMk?si=B6AzhMHn2J51FES2'
            }
        ]
    },
    13: {
        title: 'Personas and Segments',
        desc: `Once you've interviewed users and tested your hypotheses, it's time to share what you learned with others in your organization. Communicating information is one of your most important responsibilities as a PM. It's also particularly important to ensure that everyone is in agreement about who the product's users are.`,
        links: [
            {
                title: 'User Personas Explained',
                url: 'https://youtu.be/Ehw8lAZMJMM?si=bZxqCRTXnU5rzOHK'
            },
            {
                title: '50 must-see user persona templates',
                url: 'https://www.justinmind.com/blog/user-persona-templates/'
            }
        ]
    },
    14: {
        title: 'User Flows',
        desc: `When you use any digital product, you usually have to go through a series of steps before you get to the value that it provides. Sometimes this sequence can be short, like going to Google, entering a search query, then clicking on the first result it returns. Sometimes the sequence is long. Even if it's quick to register for Facebook, it can take a long time to add all your friends and find relevant groups so that you're getting the value out of Facebook.
        \n
        It's essential for a PM to understand these flows, or the ways users interact with—and get value out of—your product. On some websites, like Wikipedia, users flow endlessly, bouncing from link to link. Other sites have a specific action that they're encouraging you to end with, like making a purchase on Amazon.`,
        links: [
            {
                title: 'User flow tutorial',
                url: 'https://youtu.be/TIV1y11xz7k?si=zIcZRwlU_KnGRmXm'
            }
        ]
    },
    15: {
        title: 'Funnels',
        desc: `Now that you've mastered user flows, it's time to dive deeper into the user experience and think about your funnel. The funnel is a widely used concept that explains, visualizes, and helps you analyze your user flow and—importantly—where users are abandoning it. Understanding this concept is a critical step to becoming an effective PM.`,
        links: [
            {
                title: 'The Sales Funnel explained',
                url: 'https://youtu.be/iYEr6zxSZAI?si=eX0zjcfBYhQfbKVG'
            }
        ]
    },
    16: {
        title: 'Market Sizing',
        desc: `A frequent problem that you'll encounter as a PM is trying to assess whether or not it's worth pursuing a specific business opportunity. For example, if your company wants to build a product for managing collections of vintage oil cans, you probably won't have many potential buyers. Would the limited number of potential buyers even be able to afford your product? Will there be enough of them to justify the cost of creating the product? How many of them could you realistically capture?
        \n
        Product managers need to answer these types of questions while working with imperfect information. For instance, finding the total number of vintage oil can collectors in the United States could be impossible. But you still need something to base your recommendations on. In this checkpoint, you will learn how to turn imperfect information into reasonable guesses.`,
        links: [
            {
                title: 'How to Estimate Market Size for a New Product',
                url: 'https://youtu.be/B2tjqb3uo88?si=fqoSa2gJLeKdflU_'
            },
            {
                title: 'How To Calculate Total Addressable Market ',
                url: 'https://youtu.be/c9P8i_9LPU0?si=H1BzTz_f-63CiPdo'
            }
        ]
    },
    17: {
        title: 'Competitive Analysis',
        desc: `Most products have competitors. As a product manager, you have a responsibility to understand the competitive landscape. In analyzing other companies and their products, you need to consider many factors, including brand and market awareness, features, customer preferences, and pricing.
        \n
        Markets move fast, and you'll need to make decisions quickly. This means you also need ways to quickly analyze your competition. The better you get at this, the better you'll be at making the right decisions when the time comes.`,
        links: [
            {
                title: 'Competitor Analysis Framework',
                url: 'https://youtu.be/Z4Qs3dLIPsg?si=4lNqdHOjr-CU5RzK',
            }
        ]
    },
    18: {
        title: 'Strategy',
        desc: `Coming up with ideas for improving your product is easy. In fact, as a PM, you will likely be inundated with ideas and feature requests—from your team, your customers, and your bosses. But how will you decide which features to develop, and—no less important—which features not to develop? This is where product strategy comes into play.`,
        links: [
            {
                title: 'How to Create Your Lean Canvas',
                url: 'https://youtu.be/FjB_e7UO1hc?si=WwCHFSlsocTyjOVo',
            }
        ]
    }
}