export default {
    title: 'PM Career Skills',
    desc: 'Developing career skills is an important aspect of any job search. Here are some resources that can help you develop and refine your skills:',
    1: {
        title: 'Meetups',
        desc: 'Meetups are a great way to network and learn new skills in your field. Check out the following resources to find meetups in your area:',
        links: [
            {
                title: 'Meetup',
                url: 'https://www.meetup.com/'
            },
            {
                title: 'Eventbrite',
                url: 'https://www.eventbrite.com/'
            }
        ]
    },
    2: {
        title: 'Hackathons',
        desc: 'Hackathons are events where developers, designers, and other professionals come together to collaborate on projects and learn new skills. Check out these resources to find hackathons near you:',
        links: [
            {
                title: 'UX/UI Design Hackathons',
                url: 'https://bootcamp.uxdesign.cc/ui-ux-design-hackathons-you-can-earn-rewards-prizes-for-participating-46979957e073'
            },
            {
                title: 'Hackathon.com',
                url: 'https://www.hackathon.com/'
            }
        ]
    },
    3: {
        title: 'Job Fairs',
        desc: 'Job fairs are events where employers and job seekers come together to connect and network. Here are some resources for finding job fairs in your area:',
        links: [
            {
                title: 'National Career Fairs',
                url: 'https://www.nationalcareerfairs.com/'
            },
            {
                title: 'Career Showcase',
                url: 'https://careershowcase.com/'
            },
            {
                title: 'Job Fairs Near Me',
                url: 'https://www.jobfairsnearme.com/'
            }
        ]
    },
    4: {
        title: 'Salary Negotiation',
        desc: 'Salary negotiation is the process of discussing and agreeing on a salary with an employer. It can be a nerve-wracking experience, but it is an important skill to master. In order to negotiate effectively, it is important to research industry standards, know your worth, and be confident in your value. Check out the links below to learn more about salary negotiation.',
        links: [
            {
                title: 'Effective Salary Negotiation Tips',
                url: 'https://www.indeed.com/career-advice/pay-salary/salary-negotiation-tips'
            }
        ]
    },
    5: {
        title: "Behavioural Interview",
        desc: "A behavioural interview is a type of job interview that focuses on a candidate's past behaviour in order to predict their future performance. It is based on the idea that the best predictor of future behaviour is past behaviour.",
        links: [
          {
            title: "How to Prepare for a Behavioral Interview",
            url: "https://www.thebalancecareers.com/how-to-prepare-for-a-behavioral-interview-2061209"
          },
          {
            title: "The STAR Method: Answer Behavioral Interview Questions Like a Pro",
            url: "https://www.internships.com/career-advice/interview/the-star-method"
          },
          {
            title: "How to Master the Behavioral Interview",
            url: "https://youtu.be/IV30jAw7dxA"
          },
        ]
      },
    6: {
        title: 'Handling NDAs',
        desc: 'An NDA, or non-disclosure agreement, is a legal document that protects confidential information. If you are working in a field that deals with sensitive information, such as technology or healthcare, you may be required to sign an NDA. It is important to understand the terms of the agreement and know how to handle confidential information. Check out the links below to learn more about NDAs.',
        links: [
            {
                title: 'What is a Non-Disclosure Agreement (NDA)?',
                url: 'https://en.wikipedia.org/wiki/Non-disclosure_agreement'
            },
            {
                title: '7 Things to Know About Non-Disclosure Agreements',
                url: 'https://www.legalzoom.com/articles/7-things-to-look-for-before-you-sign-a-nondisclosure-agreement'
            }
        ]
    },
    7: {
        title: 'Biography Writing',
        desc: 'Writing a biography is a great way to showcase your professional accomplishments and highlight your unique skills and experiences. It can be a challenging task, but there are many resources available to help you write a compelling and effective biography. Check out the links below to learn more about biography writing.',
        links: [
            {
                title: '21 Inspiring UX Designer Resumes',
                url: 'https://www.casestudy.club/journal/ux-designer-resume'
            },
            {
                title: 'How to Write a Short Bio (With Examples)',
                url: 'https://www.indeed.com/career-advice/career-development/how-to-write-a-short-bio'
            },
            {
                title: 'How to Write a Professional Bio for LinkedIn, Twitter & More',
                url: 'https://www.huffpost.com/entry/how-to-write-a-profession_b_4644730'
            },
            {
                title: 'Write a Personal Bio That Draws Attention',
                url: 'https://www.indeed.com/career-advice/resumes-cover-letters/write-a-personal-bio-that-draws-attention'
            }
        ]
    },
    8: {
        title: 'Case Studies',
        desc: 'Case studies are detailed analyses of real-world problems, usually conducted by businesses, academics, or other organizations. They are used to analyze and evaluate a variety of factors that contribute to success or failure in specific situations, such as market trends, customer behavior, and business processes. To learn more about case studies and their role in UX/UI design, check out the links below:',
        links: [
            {
                title: 'How to write a case study for your UX portfolio',
                url: 'https://www.invisionapp.com/inside-design/how-to-write-a-ux-case-study'
            },
            {
                title: '14 inspiring UX designer portfolio examples',
                url: 'https://webflow.com/blog/ux-designer-portfolio?'
            },
            {
                title: 'The case study is dead! Long live the case study!',
                url: 'https://influitive.com/blog/the-case-study-is-dead-long-live-the-customer-story/'
            }
        ]
    },
    9: {
        title: 'Website Publishing',
        desc: 'Website publishing refers to the process of taking a website from development to live on the internet. This involves registering a domain name, choosing a web hosting provider, uploading files to the server, and configuring the site for optimal performance. To learn more about website publishing and how it relates to UX/UI design, check out the links below:'
    },
    10: {
        title: 'Organisation & Design',
        desc: 'Effective organization and design are critical components of UX/UI design. Good organization ensures that users can easily find the information they need, while good design makes that information easy to understand and use. To learn more about how organization and design impact UX/UI, check out the links below:',
        links: [
            {
                title: '7 Gestalt principles of visual perception',
                url: 'https://www.usertesting.com/resources/topics/gestalt-principles'
            },
            {
                title: 'The ultimate guide to website navigation design',
                url: 'https://blog.hubspot.com/website/main-website-navigation-ht'
            },
            {
                title: 'Designing for accessibility is not that hard',
                url: 'https://uxdesign.cc/designing-for-accessibility-is-not-that-hard-c04cc4779d94'
            }
        ]
    },
    11: {
        title: 'Resume',
        desc: 'A resume is a summary of your skills, work experience, education, and other relevant information that can help you get hired. It is usually the first thing that potential employers see, so it is important to make a good first impression with a well-crafted resume.'
    },
    12: {
        title: 'LinkedIn',
        desc: 'LinkedIn is a social networking platform for professionals. It can be used to build your personal brand, network with other professionals in your field, and find job opportunities. Having a strong LinkedIn profile can help you stand out to potential employers and colleagues.',
        links: [
            {
                title: 'LinkedIn Website',
                url: 'https://www.linkedin.com/',
            }
        ]
    },
    13: {
        title: "Art of Cover Letters",
        desc: "A behavioural interview is a type of job interview that focuses on a candidate's past behaviour in order to predict their future performance. It is based on the idea that the best predictor of future behaviour is past behaviour.",
        links: [
          {
            title: "How to Prepare for a Behavioral Interview",
            url: "https://www.thebalancecareers.com/how-to-prepare-for-a-behavioral-interview-2061209"
          },
          {
            title: "The STAR Method: Answer Behavioral Interview Questions Like a Pro",
            url: "https://www.internships.com/career-advice/interview/the-star-method"
          },
          {
            title: "How to Master the Behavioral Interview",
            url: "https://youtu.be/IV30jAw7dxA"
          },
        ]
      }
}