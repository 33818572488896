import React from "react";
import classes from "./Footer.module.css";

const Footer = () => {
  return (
    <footer className={classes.layoutFooter}>
      <p>Keen on mastering Product Management with live mentoring? </p>
            <a href="https://www.pmcademy.com/" target="_blank">
                <button>Learn more</button>
            </a>
    </footer>
  );
};

export default Footer;
