export default {
    title: 'Tech Fundamentals',
    desc: `If you are new to technology, this module will introduce a lot of new information. It's okay to get a little overwhelmed. Take your time reading through the checkpoints, take notes, review the concepts, and be patient with yourself. 
    \n
    You may not know a lot about technology yet, but with some hard work, determination, and all the support available to you in this program—you will become a true techie soon enough. As a PM you will need to get a good overview as to how developers and designers function too.`,
    1: {
        title: 'Agile Development',
        desc: `You'll learn about the core principles of agile development and explore how agile differs from other development methodologies. As a product manager, you'll help organize the development workflow and make sure that your team is completing a variety of tasks. In this work, agile will be one of the key tools in your toolbox`,
        links: [
            {
                title: `What's the difference between Agile and Scrum?`, 
                url: 'https://youtu.be/tKFs2xQRRIE?si=7tY15R2TNx9qP3sH',
            }
        ]
    },
    2: {
        title: 'Writing Stories',
        desc: `Epics and user stories connect high-level roadmap ideas with concrete, executable tasks that can be timeboxed and tracked. These help teams adapt quickly and easily to changes. For instance, user research or technical limitations often cause implementation to differ from what the team originally planned.
        \n
        Highly detailed business requirements—like those you'd find in waterfall development—make it challenging to respond to these changes and all the ripple effects they have on other tasks. But in agile, epics and stories provide a fluid and actionable approach and prevent an endless cycle of complicated revisions to comprehensive documentation.`,
        links: [
            {
                title: 'The Power of Sketching in UX Design', 
                url: 'https://bootcamp.uxdesign.cc/the-power-of-sketching-f207c8d8ed2b',
            }
        ]
    },
    3: {
        title: 'Agile Tools',
        desc: 'Information architecture (IA) is the process of organizing, structuring, and labeling content in a way that makes it easy for users to find and navigate. It helps designers to create a clear and organized structure for a website or app.',
        links: [
            {
                title: 'What are Agile Epics, User Stories, and Story Points?', 
                url: 'https://youtu.be/ogV2r9579WI?si=RPwSwax5lsBC6g3L',
            }
        ]
    },
    4: {
        title: 'Backlogs & Sprints',
        desc: `A big part of your role as a product manager will be maximizing the use of your developers' time. You will do this by maintaining a prioritized backlog of user stories. Then, you will work to make the stories developer-ready and assign them to your team. This process is known as backlog refinement and sprint planning.`,
        links: [
            {
                title: 'Product Backlog Refinement in a Nutshell', 
                url: 'https://youtu.be/POpCiEXOWkE?si=uUcE86WjCiuxYScG',
            }
        ]
    },
    5: {
        title: 'Testing',
        desc: `Validating that features will function as expected is an important step in the development process. On top of making sure everything works as it should (and on multiple devices), you should also make sure that the software knows what to do in an error state. 
        \n
        For instance, what happens if a user enters letters in a form field that is set up to only accept numbers? How is it recorded if someone enters the last name "de L'Orange"? What happens if a user repeatedly clicks Buy on the checkout screen? You'll need to anticipate all these different situations and ensure that the product is ready to handle them.`,
        links: [
            {
                title: 'A Day In The Life of a QA Tester at a Software Development Company', 
                url: 'https://youtu.be/ChhYCujkMZ0?si=ykV0IqVSlC7Q5MsR',
            },
            {
                title: 'Three Key Phases of Exploratory Testing',
                url: 'https://youtu.be/CJF-jCQeMys?si=aWzt1YxT8xpY3ydV'
            }
        ]
    },
    6: {
        title: 'Retros & Demos',
        desc: `Wrapping up a sprint involves many details that need to be addressed. If new features were completed, you and your team would want to communicate the details of this accomplishment to your collaborators and celebrate the new functionality. 
        \n
        If any issues arose during the sprint—such as team members being late for stand-up meetings or confusion in coordination with internal partners—these should be identified and learned from at each sprint's conclusion.`,
        links: [
            {
                title: 'Agile Retrospectives Done Right', 
                url: 'https://youtu.be/dJNCHvqKljU?si=anCJ_R_28hv5kx_6',
            },
            {
                title: 'How to Run a Retrospective With a Team',
                url: 'https://youtu.be/tASKgeHPSHE?si=i1B8VGSF8Uu-nLJC'
            }
        ]
    },
    7: {
        title: "API's",
        desc: `Over recent years, APIs have become increasingly more popular in software development, and every product manager is likely to, at some point in their career, come across the question "Can you use an API for that?" To better answer that question and understand the context in which it is asked, it's helpful to know a little about what APIs are and how they function.`,
        links: [
            {
                title: 'API Economy', 
                url: 'https://youtu.be/fdffKLl8OpA?si=XeG6HwN3ZlvsEStA',
            },
            {
                title: 'What is an API?',
                url: 'https://youtu.be/s7wmiS2mSXY?si=Ev1joqJ_0XRGT3lp'
            }
        ]
    },
    8: {
        title: 'Frontend-Backend Infrastructure',
        desc: `You've probably heard the terms frontend and backend, but maybe you are uncertain as to what they mean. In this checkpoint, you will learn about the difference between frontend, backend, and infrastructure. 
        \n
        These will tie back to the previous checkpoint; in combination with APIs, these architectural elements allow for a user-friendly interface, an effective processing engine, and a reliable structure for exchanging all the data necessary.`
    },
    9: {
        title: 'Platforms',
        desc: `he term platform is thrown around a lot. But what is a platform, exactly? Turns out, you regularly engage with platforms of all kinds. Computers, cell phones, and video game systems are all platforms. Invisible structures and tools like databases, programming languages, and systems that power applications are also platforms.
        \m
        Fundamentally, a platform is a foundation offering core services that encourage third parties to build complementary products that extend the platform's core functionality. `
    },
    10: {
        title: 'UI Tech',
        desc: `The products you work on will likely have a user interface (UI). Some products don't have a user interface, but these are rare. UI technology has evolved from supporting very basic tasks in the 1980s to supporting contemporary tools that offer amazingly intuitive and dynamic experiences today. 
        \n
        Nowadays, even highly specialized infrastructure tools are likely to offer a user-friendly interface option in addition to more developer-facing coding interaction.`,
        links: [
            {
                title: 'What is UI vs. UX Design?',
                url: 'https://youtu.be/TgqeRTwZvIo?si=AMCZaOFPt2vBiti_'
            }
        ]
    },
    11: {
        title: 'Data what ?',
        desc: `Many of the most popular technology products and platforms are built on personalized or predictive technologies. Amazon suggests other products you might purchase based on your shopping history. Google personalizes your search results based on sites you frequently visit. Spotify recommends music based on your listening habits. These products are built by processing massive amounts of data.
        \n
        Analyzing a product's user data can help companies predict user behavior and identify points of influence that can nudge users toward desirable behaviors. `,
        links: [
            {
                title: 'What is Data Science?',
                url: 'https://youtu.be/RBSUwFGa6Fk?si=gm6t9UCZEaQT9MqT'
            },
            {
                title: 'Machine Learning vs Deep Learning',
                url: 'https://youtu.be/q6kJ71tEYqM?si=tS_FUgcT7_i9dVsS'
            }
        ]
    },
    12: {
        title: 'Dev Intro',
        desc: `Much of your time as a product manager will be spent working with your developers as they build the features and complete the stories that you created. Many issues will come up as they do their work—features that can't be built to specifications, technical challenges that need resolution, or personnel issues that threaten to derail your projects.
        \n
        To collaborate successfully with your development team, you need to understand developers' day-to-day work.`,
        links: [
            {
                title: 'The Product Development Process at Google - Joe Faith',
                url: 'https://youtu.be/yyucwHDgAco?si=frxYyQo547TO2XiM'
            },
            {
                title: 'A REAL Day in the Life of a Software Engineer',
                url: 'https://youtu.be/j1fc0FlCjyI?si=X2Q-r6cbR3eUGtJf'
            }
        ]
    },
    13: {
        title: 'Version Control',
        desc: `Developers don't work on a single, monolithic application. The team delegates out tasks so that different developers will be working on different parts of the application simultaneously. Work done by different team members at different times will come together to enable a single new feature or update. 
        \n
        The lead engineer or engineering architect will manage how these different portions of work are broken up and delegated out. Specialized source code management (SCM) software is used to orchestrate who is doing what with which versions or branches of code and to determine when a portion is effectively locked and released as a build.`
    },
    14: {
        title: 'How Databases Work',
        desc: `As a product manager, you'll interact with data in a variety of ways. Some companies expect product managers to have an intimate knowledge of data. In other companies, you will work alongside a data scientist or data analyst who will handle your data needs.
        \n
        In either case, knowing the basics about databases and being familiar with data tools like SQL is going to be tremendously helpful to your career. It will allow you to understand critical aspects of the products you'll manage and have intelligent discussions with your collaborators. And not only that, but it will make you a much more attractive candidate to most companies in today's data-driven business environment.`,
        links: [
            {
                title: 'What is SQL?',
                url: `https://youtu.be/27axs9dO7AE?si=78qqRRHuHTVQSbj2`
            },
            {
                title: 'Relational vs. Non-Relational Databases',
                url: 'https://youtu.be/E9AgJnsEvG4?si=tJTAN0TcWwvlUJZr'
            }
        ]
    },
    15: {
        title: 'Setup pgAdmin',
        desc: `In this checkpoint, you will use a SQL database management tool called PostgreSQL to begin exploring databases. You'll install and navigate the Postgres graphical interface, called pgAdmin, to get a sense of how databases are set up before you begin writing your own queries.`,
        links: [
            {
                title: 'pgAdmin Tutorial - How to Use pgAdmin',
                url: 'https://youtu.be/WFT5MaZN6g4?si=bJ0brTuiM_h270LU'
            }
        ]
    },
    16: {
        title: 'SQL Queries',
        desc: `SQL queries are a crucial tool for Product Managers, enabling them to directly access and analyze data from databases. This skill is essential for making data-driven decisions, understanding customer behavior, and tracking product performance metrics.
        \n
        By knowing SQL, Product Managers can communicate more effectively with technical teams and gain autonomy in accessing and interpreting data, leading to quicker, more informed decision-making and a deeper understanding of their product's impact.`
    },
    17: {
        title: 'SQL Joins',
        desc: `SQL joins are commands in SQL used to combine rows from two or more tables, based on a related column between them. Understanding joins is crucial for Product Managers as it enables them to merge different sets of data for comprehensive analysis, such as combining user demographics with their purchasing behavior.
        \n
        Knowing SQL joins allows Product Managers to gain a more holistic view of the customer journey and product performance. This insight is vital for making informed decisions about product development, marketing strategies, and improving user experience.`
    },
    18: {
        title: 'Colaboratory Intorduction',
        desc: `In this checkpoint, you'll get up and running with Google Colaboratory, or Colab. Colab is a platform for data analysis, and it is the development and research environment you'll use in this program for practicing Python.`,
    },
    19: {
        title: 'Variables & Collections',
        desc: `Now that you have Colab set up, it's time to start learning Python! The first concept you'll tackle is variables. Variables are one of the fundamental building blocks of programming. In this checkpoint, you will learn what variables are, why they're useful, and the different kinds of data types a variable can take on in Python.`,
    },
    // 20: {
    //     title: 'Functions',
    //     desc: 'Crazy-Eights is a fast-paced ideation exercise that encourages participants to generate as many ideas as possible in a short amount of time. It\'s a great way to quickly come up with a lot of different ideas and concepts. To play Crazy-Eights, you need a group of people, a whiteboard or large piece of paper, and some markers or pens. The goal is to come up with as many ideas as possible in eight minutes. The more creative and unconventional the ideas, the better!',
    // },
    // 21: {
    //     title: 'Conditional Logic',
    //     desc: 'Crazy-Eights is a fast-paced ideation exercise that encourages participants to generate as many ideas as possible in a short amount of time. It\'s a great way to quickly come up with a lot of different ideas and concepts. To play Crazy-Eights, you need a group of people, a whiteboard or large piece of paper, and some markers or pens. The goal is to come up with as many ideas as possible in eight minutes. The more creative and unconventional the ideas, the better!',
    // },
    // 22: {
    //     title: 'List & Dictionaries',
    //     desc: 'Crazy-Eights is a fast-paced ideation exercise that encourages participants to generate as many ideas as possible in a short amount of time. It\'s a great way to quickly come up with a lot of different ideas and concepts. To play Crazy-Eights, you need a group of people, a whiteboard or large piece of paper, and some markers or pens. The goal is to come up with as many ideas as possible in eight minutes. The more creative and unconventional the ideas, the better!',
    // },
    // 23: {
    //     title: 'Objects & Modules',
    //     desc: 'Crazy-Eights is a fast-paced ideation exercise that encourages participants to generate as many ideas as possible in a short amount of time. It\'s a great way to quickly come up with a lot of different ideas and concepts. To play Crazy-Eights, you need a group of people, a whiteboard or large piece of paper, and some markers or pens. The goal is to come up with as many ideas as possible in eight minutes. The more creative and unconventional the ideas, the better!'
    // }
}