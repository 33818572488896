import React, { useState } from 'react'
import 'react-responsive-modal/styles.css';
import classes from "./MainContainer.module.css";
import { ReactComponent as MySvg } from  '../roadmap/design.svg'
import Lines from '../components/Lines';
import data from '../roadmap/data'
import Pill from '../components/Pill';
import Raw from '../components/Raw';
import { Modal } from 'react-responsive-modal';
import {part1, part2, part3, part4, part5} from '../content/index'

function MainContainer() {


  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [desc, setDesc] = useState('');
  const [links, setLinks] = useState(null)

  const onCloseModal = () => {
    setOpen(false)
    setLinks(null)
    setDesc('')
    setTitle('')
  };

  const getData = (id) => {
    switch (id){
      case "1": return part1;break;
      case "2": return part2;break;
      case "3": return part3;break;
      case "4": return part4;break;
      case "5": return part5;break;
      default: return 'Try clicking some other section';
    }
  }

  const handleClick = (e) => {
    e.preventDefault();
    let id = e.target.getAttribute('id')
    let subid = e.target.getAttribute('subid')
    let data = getData(id)
    if (!id) {
      return
    }
    // if (id == 'game') {
    //   window.open('https://www.uxuiopen.com/play/', 'blank');
    //   return
    // }
    if (subid) {
      setTitle(data[subid].title)
      setDesc(data[subid].desc)
      if (data[subid].links) {
        setLinks(data[subid].links)
      } 
      else {
        setLinks(null)
      }
    }
    else {
      setTitle(data.title)
      setDesc(data.desc)
    }
    setOpen(true)
  }

  function newlineText(text) {
    return text.split('\n').map(str => <p>{str}</p>);
  }

  return (
    <div className={classes["main-container"]}>
      {
        open && (
          <Modal focusTrapped={false} classNames={{
            modal: classes["mymodal"],
            }} open={open} onClose={onCloseModal} center>
            <h1>{title}</h1>
            <p>
              {open && newlineText(desc)}
            </p>
            {
              links && (
                <div className={classes["resources"]} >
                  <h3>Resources</h3>
                  {
                    links.map((link) => {
                      return <div>
                        <a href={link.url} target='_blank'>👉 {link.title}</a>
                        </div>
                    })
                  }
                </div>
              )
            }
            <div className={classes["modalfooter"]}>
              <a href='https://pmcademy.com' target="_blank">
                Join the Product Management bootcamp at pmcademy.com
              </a>
            </div>
          </Modal>
        )
      }
      <Raw handleClick={handleClick} />
      {/* <MySvg />      */}
      {/* <svg viewBox="0 0 1280 3832" fill="none" xmlns="http://www.w3.org/2000/svg">
        <Lines />
        {
          data.pills.map((d) => {
            return (
              <Pill d={d}/>
            )
          })
        }
        
      </svg> */}
    </div>
  )
}

export default MainContainer