export default {
    title: 'Launch & Analysis',
    desc: `So far, you've focused on the planning, designing, and building of a product. But what happens when that product is built and functioning? How do you make sure that users know about it? Getting the information out to users is where your marketing team comes in.`,
    1: {
        title: 'Product Marketing',
        desc: `Marketing is an especially important part of product work when you are launching a new product or a significant update. In these situations, you'll work closely with the marketing team on tasks to promote the product. As a product manager, you will be involved in creating the marketing message to ensure that your product succeeds.
        \n
        In this checkpoint, you will learn about the work that marketing teams and other internal partners do to prepare for a product or enhancement launch, and how PMs are involved.`,
        links:  [
            {
                title: 'Seth Godin - How to Get Your Ideas to Spread',
                url: 'https://youtu.be/04pdq5IppL8?si=_W40qkFWnSsI3Dp_',
            },
            {
                title: 'Kat Mañalac - How to Launch (Again and Again)',
                url: 'https://youtu.be/3xU050kMbHM?si=4nM6gLU0tED6Zg04',
            }
        ]
    },
    2: {
        title: 'Profit & Loss',
        desc: `For most products, the goal of enhancements is either to increase the revenue your company brings in or to reduce the cost of creating or maintaining the product features. This is ultimately what product managers are responsible for; if the PM is successful, those numbers will be heading in the right direction.
        \n
        In this checkpoint, you'll learn about the methods companies use to track profit and loss, how to predict future revenue or growth, and how to better estimate the impact of product changes.`,
        links:  [
            {
                title: 'PRICING STRATEGY: How To Find The Ideal Price For A Product',
                url: 'https://youtu.be/xQWUm_eHjGk?si=0XE5p7RvugaMI9Rl',
            }
        ]
    },
    3: {
        title: 'Growth & Acquisition',
        desc: `Growth is a term used to describe any improvement in the measures of a business's success. You might think growth is the marketing team's job. That's not true. In fact, growth product manager is a new and emerging title in the field of product management. The rise of the growth PM indicates how much companies care about product growth, and their willingness to dedicate product managers to it. Even if you're not a growth PM, growth always matters to a product manager.`
    },
    4: {
        title: 'Increasing Growth',
        desc: `Now that you have a basic idea of how to track product growth, it's time to start thinking in more detail about the actions product managers can take to increase product growth. If your experience is primarily in business strategy or sales, you might even find yourself with the title of growth product manager. This is a role that focuses on the parts of the product experience that are the most critical to gaining new users and getting them to stick with your product.`,
        links: [
            {
                title: 'How to Build Habit-Forming Products',
                url: 'https://youtu.be/-jXM4NymIcA?si=vrMbr7Tz53_xl__3',
            }
        ]
    },
    5: {
        title: 'Google Sheets',
        desc: `In your product management career, you'll use many different tools to analyze data and understand how your product is doing. Spreadsheet applications like Microsoft Excel or Google Sheets are a popular tool for this kind of analysis. 
        \n
        Spreadsheet apps accept a wide variety of data, are easy to manipulate, and provide lots of built-in functions. In addition, many of them include data visualization features that can turn data insights into impressive, easy-to-understand visuals. Analyzing and presenting data is a crucial PM skill.`
    },
    6: {
        title: 'Pivot Tables',
        desc: `Imagine that you have a list of all the sales on your e-commerce website over the last 12 months. To make use of that information, you need to analyze trends and patterns. Maybe you're wondering what your sales look like by month broken down by product category. 
        \n
        Pivot tables are a feature available on spreadsheet applications that helps you avoid data overload. With a pivot table, you can summarize more extensive tables of flat, or raw, data using different kinds of aggregate functions, such as COUNT, SUM, AVERAGE, or MEDIAN.
        `
    },
    7: {
        title: 'Google Analytics',
        desc: `Most companies use an analytics tool to track product performance. Examples include Google Analytics, Adobe Analytics, Heap, Amplitude, Pendo, and KissMetrics. These, and the many other analytics tools available on the market, are very similar to each other. Learning just one of these tools will help you master their basic logic, which will be an invaluable skill no matter which analytics tool you end up using in your next role.`,
        links: [
            {
                title: 'Google Analytics Academy',
                url: 'https://analytics.google.com/analytics/academy/',
            },
        ]
    },
    8: {
        title: 'Visualisation',
        desc: `You've already learned about basic data visualizations in one of the earlier modules. Now, you will expand on that knowledge and learn how to create advanced data visualizations. These can include multiple scales and dimensions of data; you'll even learn about creating your own bespoke product dashboard. In the next module, you will dive into Tableau, a popular data visualization tool.
        \n
        Why all this focus on visualizing data? Since most companies today think of themselves as data-driven, being able to understand, manipulate, and communicate with data is quickly becoming a very important skill for prospective PMs to demonstrate.`,
        links: [
            {
                title: 'How to make a Bubble Chart in Google Sheets',
                url: 'https://youtu.be/ghpUiiqWqUo?si=DXmY9Z1yCGZtJ004',
            },
        ]
    },
    9: {
        title: 'Ship, Iterate or Kill',
        desc: `You've now heard a few times about iterative design, but just to reiterate—it's the idea that you can ship your product in a minimal state (MVP) and add to it over time.
        \n
        Iteration provides a way for you to make future versions better based on the feedback that you get from your users, business metrics, and stakeholders. You can think of it in terms of the lean startup cycle—build, measure, learn—a cycle that goes on forever.`,
    },
    10: {
        title: 'BI Tools',
        desc: `In this module, you'll get some practice using one of the most popular data visualization and business intelligence tools today: Tableau. Adding Tableau to the list of software skills on your resume will give you a significant advantage in the job market. And even better, you'll be able to apply your Tableau skills to a project to impress potential employers with.`,
        links: [
            {
                title: 'How To Use Looker as a Business User',
                url: 'https://youtu.be/sIg_lXRFqh0?si=3NMtqqeuqxmULVDy',
            },
        ]
    },
    11: {
        title: 'Tableau for PMs',
        desc: `Tableau is one of the most widely used BI tools, and it is known mostly for its ability to visualize data. As a product manager, knowing Tableau will give you some specific advantages. Most importantly, it will allow you to do your own data analysis.
        \n
        In larger organizations where you might be collaborating with data analysts, knowing what they do on a deeper level will make you a more effective collaborator. Some larger organizations even hire a Tableau engineer specifically for the task of connecting data and creating new dashboards.
        \n
        And if you end up working in a growing startup organization, gathering data insights could fall under the responsibility of the product manager, so knowing how to use Tableau will be a valuable skill.`,
        links: [
            {
                title: 'How to Install Tableau and Create First Visualization',
                url: 'https://youtu.be/6xv1KvCMF1Q?si=gqApq3gu5MNm1u1k',
            }
        ]
    },
    12: {
        title: 'Visualisations',
        desc: `Tableau is well-known not just for its ability to help you do data analysis, but also for its powers of data visualization. In the previous checkpoint, you connected to the Superstore dataset. Now, you'll be using that dataset to build out your first Tableau visualizations.`,
      },
      13: {
        title: 'Aggregation',
        desc: `In this checkpoint, you'll focus on common calculated fields that you can set up when preparing data for analysis in Tableau. You'll continue working on the Superstore dataset. This topic includes the concept of aggregation, which you should remember from SQL.`,
      },
      14: {
        title: 'Storytellimg',
        desc: `One of the main advantages of knowing Tableau as a product manager is that you can use your ability to analyze and visualize data to tell your stakeholders insightful stories.
        \n
        According to Tableau, a story is "a sequence of visualizations that work together to convey information." Tableau suggests that you use visualizations to create a data narrative, which is a tool for storytelling with data. It sits somewhere between a presentation deck and a document. The story is the third and last worksheet type in Tableau.`
      },
      15: {
        title: 'Compliance',
        desc: `As a product manager, you'll need to be aware of all the dimensions of your product, even the ones you don't have direct responsibility over. One of these areas is legal issues—the laws, policies, and practices that are relevant to your product and your business. Legal issues include legal review processes, specific types of data that are legally protected, and legal obligations that your product needs to fulfill. All of these issues need to be taken into account during product development, iteration, and maintenance.
        \n
        In this checkpoint, you'll learn about some of the main legal aspects you should keep in mind, and discuss how product managers work with lawyers and legal teams.`
      },
      16: {
        title: 'Security',
        desc: ` In this checkpoint, you'll learn how to think about security in your products. What needs to be secured? How do you secure it? How can you keep your security up to date? And how do you handle security incidents when they happen?`,
    },
    17: {
        title: 'User Privacy',
        desc: `Today's technology products can track and collect details about users better than ever before. This is great news for products that attempt to reach specific users or predict their users' needs and wants, but this advanced tracking comes at a price—including a lack of user trust, government intervention, and the added responsibility to protect all that data.
        \n
        In this checkpoint, you'll dig into what privacy means for the products you'll be developing as a PM. You'll look at legal and social issues in technology that impact product decisions, and learn about best practices and the actions you can take to be privacy focused. All of this will make you a better, well-rounded product manager.`
    },
    18: {
        title: 'Ethical Issues',
        desc: `In this checkpoint, you'll learn about some of the ethical issues product managers could face. A deep understanding of these issues will make you a better leader in the product space, and a more well-rounded and thoughtful PM candidate. It will also help you consider all the relevant information if you need to handle difficult ethical choices in your work.`,
    },
    19: {
        title: 'Leadership & Stress',
        desc: `This checkpoint will focus on strategies that can help you be a better leader to others and manage the stress of the PM role. While these issues might not come up in your upcoming job interviews, they are essential to your long-term success as a product manager. As you near graduation day, it's time to think deeply about your PM career, and prepare for the challenges you might face`,
    }
}